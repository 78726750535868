<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="listAsrama"
            v-model="selAsrama"
            outlined
            @input="getListMudabbir"
            label="Asrama"
            style="min-width: 130px;"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="tambah mudabbir/ah"
            @click="toEdit"
          ></q-btn>
        </div>

        <q-input
          outlined
          label="Cari Siswa/NISN"
          v-model="searchTerm"
          @input="getListMudabbir"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nisn</th>
            <th>nama</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in listMudabbir" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nisn }}</td>
            <td>{{ val.nama }}</td>

            <td>
              <q-btn
                flat
                dense
                icon="delete_forever"
                color="negative"
                @click="del(val.id)"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="between">
        <q-btn
          outline
          color="primary"
          label="Export Excel"
          @click="download"
        ></q-btn>
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          input-class="text-orange-10"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
export default {
  data() {
    return {
      selJenjang: "",
      selAsrama: null,
      listMudabbir: [],
      fullListSiswa: [],
      listAsrama: [],
      searchTerm: "",
      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    await this.getAsramaList();
    if (this.$route.params.id_asrama > 0) {
      this.selAsrama = this.listAsrama.find(
        ({ value }) => value == this.$route.params.id_asrama
      );
      this.getListMudabbir();
    }
  },
  methods: {
    toEdit() {
      this.$router.push(`/pengasuhaneditmudabbir/${this.selAsrama.value}`);
    },
    async getAsramaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/asrama/getasrama`,
        this.$hlp.getHeader()
      );
      this.listAsrama = resp.data;
      this.$q.loading.hide();
    },
    async download() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/pengasuhan/mudabbir/getall`);
      let siswa = resp.data;

      let data = [];
      for (let item of this.listAsrama) {
        let carier = siswa.filter(function(x) {
          return x.id_asrama == item.value;
        });
        data.push(carier);
      }

      var wb = XLSX.utils.book_new();

      for (let i in this.listAsrama) {
        var ws = XLSX.utils.json_to_sheet(data[i]);
        XLSX.utils.book_append_sheet(wb, ws, this.listAsrama[i].label);
      }

      var now = moment().format("DD MMM YYYY");
      XLSX.writeFile(wb, `Data Mudabbir/ah ${now}.xlsx`);
      this.$q.loading.hide();
    },
    async del(id) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .put(`/pengasuhan/mudabbir/del/${id}`, this.$hlp.getHeader())
        .then((resp) => {});
      this.$q.loading.hide();
      await this.getListMudabbir();
    },
    async getListMudabbir() {
      this.$q.loading.show({
        message: "sedang mengambil data",
      });

      var src = "%25";
      if (this.searchTerm != "") src = this.searchTerm;

      let offset = (this.pagination.current - 1) * this.pagination.limit;

      await this.$http

        .get(
          `/pengasuhan/mudabbir/list/${this.selAsrama.value}/${src}/1/${this.pagination.limit}/${offset}`,
          this.$hlp.getHeader()
        )

        .then((resp) => {
          resp.data.forEach((element) => {
            element.check = false;
          });
          this.listMudabbir = resp.data;
          this.$q.loading.hide();
        });

      await this.$http
        .get(
          `/pengasuhan/mudabbir/list/${this.selAsrama.value}/${src}/1`,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.fullListSiswa = resp.data;
          this.pagination.max = Math.ceil(
            this.fullListSiswa.length / this.pagination.limit
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
